import React from "react";
import "./about_us.css";

const AboutUs = () => {
  return (
    <div className="about-container" id="about_us">
      <div className="title">
        <img
          src={`${process.env.REACT_APP_ASSETS}/about_us.png`}
          alt="about text"
        ></img>
      </div>
      <div className="team">
        <div>
          <img
            src={`${process.env.REACT_APP_ASSETS}/team1.png`}
            alt="team 1"
          ></img>
          <div className="glitch-wrapper">
            <div className="glitch" data-text="Leon Ng">
              Leon Ng
            </div>
          </div>
          <h1>Co-Founder (CCO)</h1>
          <p>
            Thank you for your interest in Nudle Dudle. My name is Leon Ng. I'm
            just a guy who loves NFTs af.
            <br />
            NFT space opened up a whole new world of possibilities for me,
            allowing me to learn new things, and express my creativity in very
            new ways. <br />
            I'm so excited to be the CEO and CFO of Nudle Dudle, a project that
            I've been working on for over a year. I believe that NFTs are more
            than just digital art. They're experiences, values, and the future
            of technology.
            <br />
            It will not take long time until you notice why Nudle Dudle is the
            best NFT experiences you have ever joined
          </p>
          <a
            href="https://twitter.com/home"
            target="_blank"
            rel="noreferrer"
            className="link-about"
          >
            <img
              src={`${process.env.REACT_APP_ASSETS}/twitter-logo.png`}
              alt="twitter"
            ></img>
          </a>
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_ASSETS}/team2.png`}
            alt="team 2"
          ></img>
          <div className="glitch-wrapper">
            <div className="glitch" data-text="Drake Tan">
              Drake Tan
            </div>
          </div>
          <h1>Co-Founder (CTO)</h1>
          <p>
            I'm not as much of an art connoisseur as Leon, but I know that
            technology and experience are key to a successful web3 project.{" "}
            <br />
            I'm a man of action, so I'll let the Whitepaper and Roadmap speak
            for themselves. I'm Drake, and I'm here to say “Dev is doing
            something special”
          </p>
          <a
            href="https://twitter.com/home"
            target="_blank"
            rel="noreferrer"
            className="link-about"
          >
            <img
              src={`${process.env.REACT_APP_ASSETS}/twitter-logo.png`}
              alt="twitter"
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
