import React, { StrictMode } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import MintTemplate from "../components/Mint/MintTemplate";
import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";
import "../styles/mint.css";

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
};
const Mint = () => {
  return (
    <>
      <div className="container-mint">
        <Menu></Menu>
        <div className="mint-page">
          <StrictMode>
            <Web3ReactProvider getLibrary={getLibrary}>
              <MintTemplate />
            </Web3ReactProvider>
          </StrictMode>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Mint;
