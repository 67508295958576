import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatDate } from "../../utils/helpers";
import "./daily.css";

const Daily = (props) => {
  const [data, setData] = useState([]);
  const [dataDaily, setDataDaily] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [countdownTime, setCountdownTime] = useState(0);

  useEffect(() => {
    setCountdownTime(dataDaily.time_line);
  }, [dataDaily.time_line]);
  useEffect(() => {
    // Update time remaining every second
    const timer = setInterval(() => {
      setCountdownTime((prevTime) => Math.max(0, prevTime - 1));
    }, 1000);

    return () => {
      // Cleanup interval on component unmount
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    fetchDaily(0);
    fetchHistory(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userAddress]);
  useEffect(() => {
    fetchHistory(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const fetchHistory = async (page) => {
    if (props.userAddress) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_END_POINT}/api/v1/get-histoty?page=${page}`,
          {
            wallet: props.userAddress,
          }
        );

        const responseData = response.data.data;
        setData(responseData.data);
        setTotalPages(responseData.last_page);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchDaily = async (check) => {
    if (props.userAddress) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_END_POINT}/api/v1/attendance-daily`,
          {
            wallet: props.userAddress,
            check: check,
          }
        );

        setDataDaily(response.data.data);

        // Calculate time remaining in seconds
        const timeNext = new Date(response.data.data.time_next).getTime();
        const currentTime = new Date().getTime();
        const remainingTime = Math.max(0, timeNext - currentTime);

        setTimeRemaining(remainingTime / 1000);
        if (response.data.status === false) {
          alert(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    // Update time remaining every second
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => Math.max(0, prevTime - 1));
    }, 1000);

    return () => {
      // Cleanup interval on component unmount
      clearInterval(timer);
    };
  }, []);

  const handleDailyClick = (check) => {
    if (timeRemaining <= 0 || dataDaily.time_line <= 0) {
      fetchDaily(check);
    }
  };
  return (
    <>
      {props.userAddress ? (
        <div className="daily-container w-full h-full">
          <div className="header-daily">
            <b className="ml-[5px]">Daily</b>
            <div className="daily-part">
              <div className="daily-show">
                <img
                  src="https://amber-ready-amphibian-362.mypinata.cloud/ipfs/QmecRiPu5WPUjenQPsrddrtEYV8ipy4NpArnDoP9mWuzy5?_gl=1*1nt3d7e*_ga*MTY3MzU1MTIwNy4xNjgxMjY5MTI3*_ga_5RMPXG14TE*MTcwMzgyMzg0OS42LjEuMTcwMzgyMzg3My4zNi4wLjA."
                  alt="daily gif"
                ></img>
                <div
                  className="border points-check-in-boxes rounded-sm text-center px-1 py-2 mb-3 ml-2 ml-lg-3 unticked-box"
                  data-points="40"
                >
                  <div className="text-2xl font-weight-bold">+50 🍭</div>
                </div>
              </div>
              <div className="daily-content">
                <label>
                  You have collected {dataDaily && dataDaily.collected_lolli}{" "}
                  🍭. Please continue to take attendance to collect more
                </label>
                {countdownTime <= 0 ? (
                  <button
                    className="testbutton"
                    onClick={() => handleDailyClick(1)}
                  >
                    Claim 50 🍭
                  </button>
                ) : (
                  <button
                    className="testbutton hover:cursor-not-allowed"
                    disabled
                  >
                    Claim 50 🍭 in {formatTimeRemaining(countdownTime)}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div>
            {data ? (
              <>
                <b className="ml-[5px]">History</b>
                <div className="history-tr">
                  {data.map((item, index) => (
                    <div className="history-td" key={index}>
                      <label>
                        <p>{item.name}</p>
                        <i>{formatDate(item.created_at)}</i>
                      </label>
                      <div className="text-green-600">+ {item.quantity} 🍭</div>
                    </div>
                  ))}
                </div>
                <div className="paginate">
                  {currentPage > 1 && (
                    <button onClick={() => handlePageChange(currentPage - 1)}>
                      &laquo;
                    </button>
                  )}

                  {Array.from({ length: totalPages }, (_, index) => {
                    const pageNumber = index + 1;
                    const isActive = pageNumber === currentPage;

                    if (
                      totalPages <= 7 ||
                      isActive ||
                      pageNumber === 1 ||
                      pageNumber === totalPages
                    ) {
                      return (
                        <button
                          key={pageNumber}
                          className={isActive ? `activep` : ""}
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      );
                    }

                    if (
                      currentPage > 4 &&
                      currentPage < totalPages - 3 &&
                      index === 1
                    ) {
                      return <span key={pageNumber}>...</span>;
                    }

                    if (
                      currentPage > 4 &&
                      currentPage < totalPages - 3 &&
                      index === totalPages - 3
                    ) {
                      return <span key={pageNumber}>...</span>;
                    }

                    return null;
                  })}

                  {currentPage < totalPages && (
                    <button onClick={() => handlePageChange(currentPage + 1)}>
                      &raquo;
                    </button>
                  )}
                </div>
              </>
            ) : (
              <div className="alert-bae">loading...</div>
            )}
          </div>
        </div>
      ) : (
        <div className="alert-bae">
          Not found!! Please reload the page or reconnect your wallet ...
        </div>
      )}
    </>
  );
};

export default Daily;

const formatTimeRemaining = (timeRemaining) => {
  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = Math.floor(timeRemaining % 60);

  const formattedTime = `${hours}h ${minutes}m ${seconds}s`;
  return formattedTime;
};
