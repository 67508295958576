import React, { useState, useEffect } from "react";
import { formatAddress } from "../../utils/helpers";
import "./leaderboard.css";

const LeaderBoard = (props) => {
  const [data, setData] = useState([]);
  const [highlightIndex, setHighlightIndex] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_END_POINT}/api/v1/get-member`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === false) {
          alert(data.message);
        } else {
          setData(data.data);
          const index = data.data.findIndex(
            (item) => item.wallet === props.userAddress
          );
          setHighlightIndex(index);
        }
      })
      .catch((error) => {
        alert("ERR! " + error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userAddress]);

  return (
    <>
      {props.userAddress ? (
        <>
          <b className="ml-[5px]">Leader Board</b>
          <div className="w-full h-full leader-board">
            <div>
              <h1 className="top-100">Top 100</h1>
              <div className="table-rank">
                <table className="border-collapse ">
                  <thead>
                    <tr>
                      <th className="p-2 uppercase">RANK</th>
                      <th className="p-2 uppercase ">WALLET</th>
                      <th className="p-2 uppercase ">Baby Name</th>
                      <th className="p-2 uppercase ">TWITTER</th>
                      <th className="p-2 uppercase ">DISCORD</th>
                      <th className="p-2 uppercase ">$LOLLI</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((item, index) => (
                        <tr
                          key={index}
                          className={
                            highlightIndex === index ? "highlighted-row" : ""
                          }
                        >
                          <td className="p-2  uppercase  px-10">{index + 1}</td>

                          <td className="wallet-lb p-2">
                            <img
                              src={item.src_image}
                              alt={item.wallet}
                              width={30}
                            ></img>{" "}
                            {item.wallet && formatAddress(item.wallet, 4)}
                          </td>
                          <td className="p-2  px-7 text-[#468ccf] max-w-[300px]">
                            {item.nick_name}
                          </td>
                          <td className="p-2 visited:text-purple-600 underline underline-offset-1 px-7 max-w-[300px]">
                            <a
                              href={`https://twitter.com/${item.screen_name}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.screen_name}
                            </a>
                          </td>
                          <td className="p-2  px-7 max-w-[300px]">
                            {item.discord}
                          </td>
                          <td className="p-2 ">{item.lolly_total} 🍭</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="alert-bae">
          Not found!! Please reload the page or reconnect your wallet ...
        </div>
      )}
    </>
  );
};

export default LeaderBoard;
