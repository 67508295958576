import React from "react";
import { FaTwitter, FaDiscord, FaTelegram } from "react-icons/fa";
import styles from "./Footer.module.css"; // Import CSS module for styling

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <p className="font-[Changa] text-[16px] text-white opacity-90">
          Copyright <span className="text-blue-600"> ©Nudle-Dudle</span>. All
          rights reserved.
        </p>
        <div className={styles.links}>
          <a
            href="https://twitter.com/nudledudlenft"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className={styles.icon} />
          </a>
          <a
            href="https://t.me/nudledudle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram className={styles.icon} />
          </a>
          <a
            href="https://discord.com/invite/nudledudle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaDiscord className={styles.icon} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
