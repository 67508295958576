import React from "react";
import Menu from "../components/Menu/Menu";
import "../styles/notfound.css";
import "../App.css";

function NotFound() {
  return (
    <div className="not-found-container">
      <Menu></Menu>
      <svg>
        <text x="50%" y="50%" dy=".35em" text-anchor="middle">
          COMING SOON
        </text>
      </svg>
      <p>Coming soon</p>
    </div>
  );
}

export default NotFound;
