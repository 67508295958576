import React, { useState } from "react";
import { formatAddress } from "../../utils/helpers";
import Loading from "../Loading";
import axios from "axios";
import "./profile.css";

const Profile = (props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [nickName, setNickName] = useState("");
  const [discord, setDiscord] = useState("");
  const [twitter, setTwitter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    if (props.baeData.nick_name) {
      setNickName(props.baeData.nick_name);
    }
    if (props.baeData.discord) {
      setDiscord(props.baeData.discord);
    }
    if (props.baeData.twitter) {
      setTwitter(props.baeData.twitter);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setNickName("");
    setDiscord("");
    setTwitter("");
  };

  const handleUpdateProfile = () => {
    if (props.userAddress == null) {
      alert("wallet null, please reload page!!");
    } else {
      try {
        const formData = new FormData();
        formData.append("wallet", props.userAddress);
        formData.append("discord", discord);
        formData.append("twitter", twitter);
        formData.append("nick_name", nickName);
        axios
          .post(
            `${process.env.REACT_APP_END_POINT}/api/v1/update-info`,
            formData
          )
          .then((response) => {
            alert(response.data.message);
            window.location.reload();
          })
          .catch((error) => {
            alert(error.message);
            setIsLoading(false);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
    closePopup();
  };

  return (
    <>
      {isLoading && <Loading></Loading>}
      {props.baeData && props.userAddress ? (
        <>
          <b className="ml-[5px]">Profile</b>
          <div className="profile-bae w-full">
            <div className="avatar-bae">
              {props.baeData && (
                <img src={props.baeData.src_image} alt="baby"></img>
              )}
            </div>
            <div className="info-bae">
              <div>
                <label>Baby Name: </label>
                <p>{props.baeData.nick_name && props.baeData.nick_name}</p>
              </div>
              <div>
                <label>Wallet: </label>
                <p>
                  {props.baeData.wallet &&
                    formatAddress(props.baeData.wallet, 4)}
                </p>
              </div>
              <div>
                <label>Discord:</label>
                <p>{props.baeData.discord && props.baeData.discord}</p>
              </div>
              <div>
                <label>Twitter:</label>
                <p>{props.baeData.twitter && props.baeData.twitter}</p>
              </div>

              <button className="update-button" onClick={openPopup}>
                Update Info{" "}
              </button>

              {props.baeData.updated_info === 0 ? (
                <>
                  {" "}
                  <span className="text-blue-600 text-[15px]">(+20🍭)</span>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="alert-bae">
          Not found!! Please reload the page or reconnect your wallet ...
        </div>
      )}
      {isPopupOpen && (
        <div className="modal-background">
          <div className="modal-content">
            <h2>Update Profile</h2>
            <form onSubmit={handleUpdateProfile}>
              <div className="form-info">
                <div>
                  <label>Baby Name: </label>
                  <input
                    type="text"
                    value={nickName}
                    onChange={(e) => setNickName(e.target.value)}
                    placeholder="Drakedudle"
                    required
                  />
                </div>
                <div>
                  <label>Discord:</label>
                  <input
                    type="text"
                    value={discord}
                    onChange={(e) => setDiscord(e.target.value)}
                    placeholder="Drakedudle"
                    required
                  />
                </div>
                <div>
                  <label>Twitter:</label>
                  <input
                    type="text"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                    placeholder="Drakedudle"
                    required
                  />
                </div>
              </div>
              <button type="submit" className="update-button mb-3">
                Update Profile
              </button>
            </form>
            <button onClick={closePopup}>
              <b>Cancel</b>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
