import React, { useState } from "react";
import Markdown from "react-markdown";
import "./faq.css";

const FAQ = () => {
  const faqData = [
    {
      question: "How to get Whitelist? ",
      answer:
        "The Nudle Dudle NFT whitelist is limited to a certain number of members. To be eligible for the Whitelist, you should participate in the project early and actively. This can be done by following the project on social media, participating in the community, and completing tasks.",
    },
    {
      question: "What are the benefits of Whitelist?",
      answer:
        "Whitelist members will be given priority to mint Nudle Dudle at the lower price. You will also receive exclusive gifts, including rare NFTs, Dudle Box and $Lollipop during the Mint section",
    },
    {
      question: "What is the Total Supply?",
      answer:
        "The total supply of Nudle Dudle is 1,998. The price of the NFTs will be significantly lower than the value they offer. Our team is focused on quality and value over quantity. Nudle Dudle are designed to be the best collection that offers a valuable experience for collectors.",
    },
    {
      question: "When is the Mint Time?",
      answer:
        "The mint date for Nudle Dudle is still to be announced. However, we has hinted that the mint will take place around in February 2024.",
    },
    {
      question: "What are the benefits of holding Nudle Dudle NFTs?",
      answer:
        "Nudle Dudle are a special and unique collection that represents the entire project. Even if you can get a free Baby Dudle, your experience will be incomplete without Nudle Dudle. Holders of Nudle Dudle will receive a variety of benefits, including:\n\n•	$Lollipop airdrops and staking\n\n•	Fashion Dudle airdrops\n\n•	Dudle Box airdrops\n\n•	PvP game access\n\n•	NFT character upgrade system\n\n•	Item Shop & Black Market\n\n(For more information, please read Whitepaper)",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="FAQ-container" id="FAQ">
      <div className="title">
        <img
          src={`${process.env.REACT_APP_ASSETS}/FAQ.png`}
          alt="faq text"
        ></img>
      </div>
      <div className="content-FAQ">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                <Markdown>
                  {item.answer.replace(
                    /\bWhitepaper\b/,
                    "[Whitepaper](/white-paper)"
                  )}
                </Markdown>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
