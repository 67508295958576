import React from "react";
import { formatAddress } from "../utils/helpers";
import "../styles/loading.css";

const SignIn = (props) => {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-fuchsia-200 z-10">
        <div className="sign-in">
          <h1>SIGN-IN</h1>
          <i className="text-center">
            Baby Nudle is the Gamefi that you can nuture your Bae through
            SocialFi and Daily Missions. <br />
            <br />
            Sign In Now To Get Your Free Baby Nudle
          </i>
          <div className="button-color">
            <button
              type="button"
              // className="w-full rounded-xl py-2 text-xl uppercase  disabled:bg-[#B7B7B7] disabled:text-[#090909] disabled:hover:cursor-not-allowed"
              onClick={
                !props.isWalletConnected
                  ? props.connectWallet
                  : props.disconnectWallet
              }
            >
              <span className="flex items-center justify-center font-[Changa]">
                {!props.isWalletConnected ? (
                  <span>Connect Wallet</span>
                ) : (
                  <>
                    <span className="mr-2">
                      {props.userAddress && formatAddress(props.userAddress, 4)}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <line
                        x1="200"
                        y1="56"
                        x2="56"
                        y2="200"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="16"
                      ></line>
                      <line
                        x1="200"
                        y1="200"
                        x2="56"
                        y2="56"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="16"
                      ></line>
                    </svg>
                  </>
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
