import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Buffer } from "buffer";
import Home from "./pages/Home";
import Labs from "./pages/Labs";
import NotFound from "./pages/NotFound";
import WhitePaper from "./pages/WhitePaper";
import MyBae from "./pages/MyBae";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import WalletChecker from "./pages/WalletChecker";
import Mint from "./pages/Mint";

window.Buffer = window.Buffer || Buffer;
const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
};

export default function App() {
  return (
    <StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/lab" element={<Labs />} />
            <Route path="/white-paper" element={<WhitePaper />} />
            <Route path="/my-bae" element={<MyBae />} />
            <Route path="/walletchecker" element={<WalletChecker />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Web3ReactProvider>
    </StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
