import React from "react";
import "./overview.css";

const Overview = () => {
  return (
    <div className="overview-container" id="overview">
      <div className="title">
        <img
          src={`${process.env.REACT_APP_ASSETS}/overview.png`}
          alt="overview text"
        ></img>
      </div>
      <div className="content-overview">
        <div>
          <img
            src={`${process.env.REACT_APP_ASSETS}/overview1.png`}
            alt="overview1"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/Labs.png`}
            alt="lab text"
          ></img>
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_ASSETS}/overview2.png`}
            alt="overview1"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/about_us.png`}
            alt="about us text"
          ></img>
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_ASSETS}/overview3.png`}
            alt="overview1"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/Collections.png`}
            alt="collection text"
          ></img>
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_ASSETS}/overview4.png`}
            alt="overview1"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/FAQ.png`}
            alt="faq text"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Overview;
