import React from "react";
import "./collections.css";

const Collections = () => {
  return (
    <div className="collection-container" id="collections">
      <div className="title">
        <img
          src={`${process.env.REACT_APP_ASSETS}/Collections.png`}
          alt="collections text"
        ></img>
      </div>
      <p className="text-collection">
        Nudle Dudle is a colorful NFT collection inspired by the combination of
        human ingenuity and the vibrancy of the modern world. The collection
        features over 1,500+ colorful traits with 200+ characters that were
        drawn and completed over a period of 12 months.
        <br />
        <br />
        Nudle Dudle is the first NFT project to integrate 3 Unique Collections
        into 1 Projects, providing a visual experience for nurturing & upgrading
        your NFTs in stages and a reward system of $Lollipop through Socialfi
        tasks & engagements
        <br />
        <br />
        <span className="font-[Rubik] ">
          Baby Dudle {"⇨"} Nudle Dudle {"⇨"} Fashion Dudle
        </span>
        <br />
        <i>
          (For more information, please read{" "}
          <a href="/white-paper" target="_blank" className="text-blue-600">
            WhitePaper
          </a>
          )
        </i>
      </p>
      <div className="content-collection">
        <div>
          <img
            src={`${process.env.REACT_APP_ASSETS}/boy/1.jpg`}
            alt="collections"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/boy/2.jpg`}
            alt="collections"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/boy/3.jpg`}
            alt="collections"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/boy/4.jpg`}
            alt="collections"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/boy/5.jpg`}
            alt="collections"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/boy/6.jpg`}
            alt="collections"
          ></img>
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_ASSETS}/girl/1.jpg`}
            alt="collections"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/girl/2.jpg`}
            alt="collections"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/girl/3.jpg`}
            alt="collections"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/girl/4.jpg`}
            alt="collections"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/girl/5.jpg`}
            alt="collections"
          ></img>
          <img
            src={`${process.env.REACT_APP_ASSETS}/girl/6.jpg`}
            alt="collections"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Collections;
