import React, { useState } from "react";
import Loading from "../Loading";
import axios from "axios";
import "./register.css";

const Register = () => {
  const [wallet, setWallet] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alert, setAlert] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("wallet", wallet);
      axios
        .post(`${process.env.REACT_APP_END_POINT}/api/v1/register`, formData)
        .then((response) => {
          if (response.data.status === true) {
            setIsModalOpen(true);
          } else {
            setAlert(response.data.message);
            setIsAlert(true);
          }
        })
        .catch((error) => {
          alert(error.message);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsLoading(false);
  };

  const closeModalAlert = () => {
    setIsAlert(false);
    setAlert("");
    setIsLoading(false);
  };

  return (
    <div id="register">
      {isLoading && <Loading></Loading>}
      <div className="register">
        <div className="content-register">
          <svg>
            <text x="50%" y="50%" dy=".35em" text-anchor="middle">
              Be The First Whitelist Members
            </text>
          </svg>
          <i className="text-center">
            Register your Wallet (ETH) to become the early members
            <br />
            and receive the special gifts from Nudle Dudle
          </i>
          <form onSubmit={handleSubmit}>
            <div>
              <div className="checkPart">
                <input
                  type="text"
                  value={wallet}
                  onChange={(e) => setWallet(e.target.value)}
                  placeholder="0x..."
                  required
                />
                <button type="submit">
                  <span class="material-symbols-outlined">
                    app_registration
                  </span>
                  Register
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-background">
          <div className="modal-content">
            <h2>Registration Successful</h2>
            <p>Like & Retweet the tweet for better chances of being chosen </p>
            <a
              href="https://twitter.com/nudledudlenft/status/1738275901744517584"
              target="_blank"
              rel="noreferrer"
            >
              <button className="testbutton" onClick={closeModal}>
                Redirect
              </button>
            </a>
          </div>
        </div>
      )}

      {isAlert && (
        <div className="modal-background">
          <div className="modal-content">
            <h1>Alert</h1>
            <p>{alert}</p>
            <button className="testbutton" onClick={closeModalAlert}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
