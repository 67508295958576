export function formatAddress(value, length = 4) {
  return `${value.substring(0, length + 1)}...${value.substring(
    value.length - length
  )}`;
}

export const formatDate = (dateTimeString) => {
  const dateObject = new Date(dateTimeString);
  if (isNaN(dateObject.getTime())) {
    return "Invalid date";
  }

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };

  return dateObject.toLocaleString("en-US", options);
};
