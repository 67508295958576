import React, { useRef, useEffect, useState } from "react";
import Loading from "../components/Loading";
import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";
import "../styles/lab.css";

const items = {
  Mouth: [
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Canvas/1.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Choose/1.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Canvas/2.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Choose/2.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Canvas/3.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Choose/3.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Canvas/4.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Choose/4.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Canvas/5.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Choose/5.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Canvas/6.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Choose/6.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Canvas/7.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Choose/7.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Canvas/8.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Choose/8.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Canvas/9.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Choose/9.png`,
    },
  ],
  Items: [
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Items/Canvas/1.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Items/Choose/1.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Items/Canvas/2.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Items/Choose/2.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Items/Canvas/3.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Items/Choose/3.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Items/Canvas/4.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Items/Choose/4.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Items/Canvas/5.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Items/Choose/5.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Items/Canvas/6.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Items/Choose/6.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Items/Canvas/7.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Items/Choose/7.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Items/Canvas/8.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Items/Choose/8.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Items/Canvas/9.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Items/Choose/9.png`,
    },
  ],
  Head: [
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Head/Canvas/1.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Head/Choose/1.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Head/Canvas/2.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Head/Choose/2.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Head/Canvas/3.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Head/Choose/3.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Head/Canvas/4.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Head/Choose/4.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Head/Canvas/5.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Head/Choose/5.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Head/Canvas/6.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Head/Choose/6.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Head/Canvas/7.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Head/Choose/7.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Head/Canvas/8.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Head/Choose/8.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Head/Canvas/9.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Head/Choose/9.png`,
    },
  ],
  Eyes: [
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Canvas/1.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Choose/1.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Canvas/2.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Choose/2.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Canvas/3.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Choose/3.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Canvas/4.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Choose/4.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Canvas/5.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Choose/5.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Canvas/6.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Choose/6.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Canvas/7.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Choose/7.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Canvas/8.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Choose/8.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Canvas/9.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Choose/9.png`,
    },
  ],

  Accs: [
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Canvas/1.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Choose/1.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Canvas/2.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Choose/2.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Canvas/3.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Choose/3.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Canvas/4.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Choose/4.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Canvas/5.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Choose/5.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Canvas/6.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Choose/6.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Canvas/7.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Choose/7.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Canvas/8.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Choose/8.png`,
    },
    {
      src: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Canvas/9.png`,
      srcChoose: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Choose/9.png`,
    },
  ],
};

const categories = [
  {
    name: "Items",
    src: `${process.env.REACT_APP_ASSETS}/item.png`,
  },
  {
    name: "Accs",
    src: `${process.env.REACT_APP_ASSETS}/accs.png`,
  },
  {
    name: "Eyes",
    src: `${process.env.REACT_APP_ASSETS}/eyes.png`,
  },
  {
    name: "Head",
    src: `${process.env.REACT_APP_ASSETS}/head.png`,
  },
  {
    name: "Mouth",
    src: `${process.env.REACT_APP_ASSETS}/mouth.png`,
  },
];

const Labs = () => {
  const canvasRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    loadImages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImages, selectedCategory]);

  useEffect(() => {
    if (initialLoad) {
      const loadingTimeout = setTimeout(() => {
        setLoading(false);
        setSelectedImages({
          Items: `${process.env.REACT_APP_LINK_ART}/labs/Items/Canvas/5.png`,
          Accs: `${process.env.REACT_APP_LINK_ART}/labs/Accs/Canvas/1.png`,
          Eyes: `${process.env.REACT_APP_LINK_ART}/labs/Eyes/Canvas/8.png`,
          Mouth: `${process.env.REACT_APP_LINK_ART}/labs/Mouth/Canvas/1.png`,
          Head: `${process.env.REACT_APP_LINK_ART}/labs/Head/Canvas/6.png`,
        });
        handleCategorySelect("Items");
        loadImages();
        setInitialLoad(false);
      }, 1000);

      return () => clearTimeout(loadingTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleItemSelect = (item, category) => {
    setSelectedImages((prevImages) => ({
      ...prevImages,
      [category]: item.src,
    }));
  };

  const loadImages = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Tạo gradient
    const gradient = ctx.createLinearGradient(
      0,
      0,
      canvas.width,
      canvas.height
    );
    gradient.addColorStop(0, "rgb(128, 177, 254)");
    gradient.addColorStop(0.203125, "rgb(165, 164, 245)");
    gradient.addColorStop(0.395833, "rgb(254, 155, 204)");
    gradient.addColorStop(0.578125, "rgb(252, 234, 148)");
    gradient.addColorStop(0.71875, "rgb(212, 236, 146)");
    gradient.addColorStop(0.84375, "rgb(128, 239, 144)");
    gradient.addColorStop(1, "rgb(124, 212, 226)");

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const defaultImage = new Image();
    defaultImage.crossOrigin = "anonymous";
    defaultImage.src = `${process.env.REACT_APP_LINK_ART}/labs/body.png`;
    defaultImage.onload = () => {
      ctx.drawImage(defaultImage, 0, 0, canvas.width, canvas.height);
    };

    Object.values(selectedImages).forEach((image) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = image;
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    });
  };

  function downloadCanvasImage() {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL("image/png");

    const link = document.createElement("a");
    link.download = "nudledudle.png";
    link.href = dataURL;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      {loading && <Loading />}
      <div className="labs">
        <Menu></Menu>

        <div className="lab-container">
          <div className="canvas-container">
            <canvas ref={canvasRef} width={500} height={500}></canvas>
            <button className="testbutton" onClick={downloadCanvasImage}>
              SAVE IMAGE
            </button>
          </div>

          <div className="choose">
            <div className="category-list">
              {categories.map((category) => (
                <div
                  key={category.name}
                  className={`category-item ${
                    selectedCategory === category.name ? "selected" : ""
                  }`}
                  onClick={() => handleCategorySelect(category.name)}
                >
                  <img src={category.src} alt={category.name} />
                  {category.name}
                </div>
              ))}
            </div>
            <div className="item-list ">
              {selectedCategory &&
                items[selectedCategory].map((item) => (
                  <div
                    key={item.id}
                    className={`item`}
                    onClick={() => handleItemSelect(item, selectedCategory)}
                  >
                    <img src={item.srcChoose} alt={item.id} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Labs;
