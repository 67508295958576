import React, { useEffect, useRef } from "react";
import "./ChatList.css";
import { formatAddress } from "../../utils/helpers";

const ChatList = ({ chats }) => {
  const chatListRef = useRef(null);

  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
  }, [chats]);

  return (
    <div className="chat-list" ref={chatListRef}>
      <ul>
        {chats.map((chat) => (
          <div key={chat.id}>
            <div className="chatMessage">
              <div className="box flex">
                <p>
                  <strong>
                    {chat.username && formatAddress(chat.username, 3)}:
                  </strong>
                </p>
                <p>{chat.message}</p>
              </div>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default ChatList;
