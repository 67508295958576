import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatDate } from "../../utils/helpers";
import "./daily.css";
import Loading from "../Loading";
import Confetti from "react-confetti";

const Mission = (props) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [twitter, setTwitter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mission, setMission] = useState([]);
  const [fireWork, setFireWork] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [isCheckNowEnabled, setIsCheckNowEnabled] = useState(true);
  const [countdown, setCountdown] = useState(10);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    if (props.baeData && props.baeData.twitter_authen) {
      setTwitter(props.baeData.twitter_authen);
    } else if (props.twitter) {
      setTwitter(props.twitter);
    }

    fetchMemes(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userAddress]);
  useEffect(() => {
    fetchMemes(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const fetchMemes = async (page) => {
    if (props.userAddress) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_END_POINT}/api/v1/get-mission?page=${page}`,
          {
            wallet: props.userAddress,
          }
        );

        const responseData = response.data.data;
        setData(responseData.data);
        setTotalPages(responseData.last_page);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleMissionClick = async (mission) => {
    if (twitter == null && props.twitter == null) {
      setAlert("Please connect twitter!!");
      setIsAlert(true);
    } else {
      setIsModalOpen(true);
      setMission(mission);
      setIsCheckNowEnabled(false);
    }
  };

  const handleCheckMission = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_END_POINT}/api/v1/execute-mission`,
        {
          wallet: props.userAddress,
          missionId: mission.id,
        }
      );
      setIsModalOpen(false);
      setIsLoading(false);
      setAlert(response.data.message);
      setIsAlert(true);

      if (response.data.status === true) {
        setFireWork(true);

        // Clear Firework after 3 seconds
        const fireworkTimeout = setTimeout(() => {
          setFireWork(false);
        }, 15000);
        // Clear the timeout when the component unmounts
        return () => clearTimeout(fireworkTimeout);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_END_POINT}/api/v1/twitter/auth`,
        {
          wallet: props.userAddress,
        }
      );

      if (response.data.status === true) {
        const authUrl = response.data.data;

        window.location.href = authUrl;
      } else {
        setTwitter(response.data.data || props.twitter);
      }
    } catch (error) {
      console.error("Error during Twitter authentication:", error);
    }
  };

  const openCheckNow = () => {
    setIsWaiting(true);
    setCountdown(10);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(countdownInterval);
      setIsWaiting(false);
      setIsCheckNowEnabled(true);
    }, 10000);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsLoading(false);
    setMission(null);
  };

  const closeModalAlert = () => {
    setIsAlert(false);
    setAlert("");
  };

  return (
    <>
      {isLoading && <Loading></Loading>}
      {props.userAddress ? (
        <div className="daily-container w-full h-full">
          <b className="ml-[5px]">Mission</b>
          {data ? (
            <>
              <div className="twitter-button">
                <button
                  className={`testbutton ${
                    twitter ? "disabled hover:cursor-not-allowed" : ""
                  }`}
                  onClick={handleLogin}
                  disabled={twitter ? true : false}
                >
                  {twitter ? twitter : "Connect Twitter"}
                </button>
              </div>
              <div className="history-tr mt-5">
                {data.map((item, index) => (
                  <div className="history-td " key={index}>
                    <label>
                      <p key={index} onClick={() => handleMissionClick(item)}>
                        {item.title}
                        <span
                          className="question-mark"
                          title={item.desc_mission}
                        >
                          �
                        </span>
                      </p>
                      <i>Created at {formatDate(item.created_at)}</i>
                    </label>
                    <div className="text-green-600">+ {item.plus_lolli} 🍭</div>
                  </div>
                ))}
              </div>
              <div className="paginate">
                {currentPage > 1 && (
                  <button onClick={() => handlePageChange(currentPage - 1)}>
                    &laquo;
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => {
                  const pageNumber = index + 1;
                  const isActive = pageNumber === currentPage;

                  if (
                    totalPages <= 7 ||
                    isActive ||
                    pageNumber === 1 ||
                    pageNumber === totalPages
                  ) {
                    return (
                      <button
                        key={pageNumber}
                        className={isActive ? `activep` : ""}
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    );
                  }

                  if (
                    currentPage > 4 &&
                    currentPage < totalPages - 3 &&
                    index === 1
                  ) {
                    return <span key={pageNumber}>...</span>;
                  }

                  if (
                    currentPage > 4 &&
                    currentPage < totalPages - 3 &&
                    index === totalPages - 3
                  ) {
                    return <span key={pageNumber}>...</span>;
                  }

                  return null;
                })}

                {currentPage < totalPages && (
                  <button onClick={() => handlePageChange(currentPage + 1)}>
                    &raquo;
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="alert-bae">Please wait for new missions...</div>
          )}
        </div>
      ) : (
        <div className="alert-bae">
          Not found!! Please reload the page or reconnect your wallet ...
        </div>
      )}
      {isModalOpen && (
        <div className="modal-background">
          <div className="modal-content">
            <h2>Check Mission</h2>
            <p>
              {" "}
              <a
                href={mission.link}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 underline"
                title={mission.desc_mission}
                onClick={openCheckNow}
              >
                {mission.title}{" "}
              </a>
            </p>
            <button
              className={`testbutton mb-2 ${
                !isCheckNowEnabled ? "disabled:hover:cursor-not-allowed" : ""
              }`}
              onClick={handleCheckMission}
              disabled={!isCheckNowEnabled}
            >
              {isWaiting ? `Waiting in ${countdown}s` : "Check Now"}
            </button>
            <button onClick={closeModal}>
              <b>Cancel</b>
            </button>
          </div>
        </div>
      )}

      {isAlert && (
        <div className="modal-background">
          <div className="modal-content">
            <h1>Notification</h1>
            <p>{alert}</p>
            <button className="testbutton" onClick={closeModalAlert}>
              Close
            </button>
          </div>
        </div>
      )}

      {fireWork && <Confetti />}
    </>
  );
};

export default Mission;
