import React, { useState } from "react";
import Loading from "../Loading";
import axios from "axios";
import Confetti from "react-confetti";
import "../Register/register.css";

const EnterCode = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [fireWork, setFireWork] = useState(false);

  const handleSubmit = async (e) => {
    if (props.userAddress) {
      e.preventDefault();
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("wallet", props.userAddress);
        formData.append("code", code);
        axios
          .post(
            `${process.env.REACT_APP_END_POINT}/api/v1/enter-code`,
            formData
          )
          .then((response) => {
            setMessage(response.data.message);
            setIsModalOpen(true);
            setIsLoading(false);
            if (response.data.status === true) {
              // Set Firework to true
              setFireWork(true);

              // Clear Firework after 3 seconds
              const fireworkTimeout = setTimeout(() => {
                setFireWork(false);
              }, 10000);

              // Clear the timeout when the component unmounts
              return () => clearTimeout(fireworkTimeout);
            }
          })
          .catch((error) => {
            alert(error.message);
            setIsLoading(false);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setCode("");
    setFireWork(false);
  };
  return (
    <>
      {isLoading && <Loading></Loading>}
      {props.userAddress ? (
        <>
          <b className="ml-[5px]">Enter Code</b>
          <div className="enter-code w-full h-full">
            <form onSubmit={handleSubmit}>
              <div>
                <div className="enterCode checkPart">
                  <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Enter Your Code..."
                    required
                  />
                  <button type="submit">Send Code</button>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className="alert-bae">
          Not found!! Please reload the page or reconnect your wallet ...
        </div>
      )}
      {isModalOpen && (
        <div className="modal-background">
          <div className="modal-content">
            <h2>Notification</h2>
            <p>{message} </p>
            <button className="testbutton" onClick={closeModal}>
              OK
            </button>
          </div>
        </div>
      )}

      {fireWork && <Confetti />}
    </>
  );
};

export default EnterCode;
