import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../utils/connectors";
import { switchChain } from "../utils/switchChain";
import config from "../config";
import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";
import SignIn from "../components/SignIn";
import Loading from "../components/Loading";
import { formatAddress } from "../utils/helpers";
import Profile from "../components/Baby/Profile";
import Daily from "../components/Baby/Daily";
import Mision from "../components/Baby/Mission";
import LeaderBoard from "../components/Baby/LeaderBoard";
import EnterCode from "../components/Baby/EnterCode";
import Confetti from "react-confetti";
import axios from "axios";

import "../styles/bae.css";

const MyBae = () => {
  const [userAddress, setUserAddress] = useState(null);
  const [baeData, setBaeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { account, activate, deactivate, active } = useWeb3React();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [twitterName, setTwitterName] = useState(null);

  const handleTwitterCallback = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const oauthToken = urlParams.get("oauth_token");
      const oauthVerifier = urlParams.get("oauth_verifier");

      if (oauthToken && oauthVerifier) {
        setLoading(true);
        setActiveTab(2);
        const response = await axios.post(
          `${process.env.REACT_APP_END_POINT}/api/v1/twitter/get-user-data`,
          {
            userAddress,
            oauthToken,
            oauthVerifier,
          }
        );

        if (response.data.status === true) {
          setTwitterName(response.data.data);
        } else {
          alert(response.data.message);
        }

        const newUrlParams = new URLSearchParams(window.location.search);
        newUrlParams.delete("oauth_token");
        newUrlParams.delete("oauth_verifier");

        window.history.replaceState(
          {},
          document.title,
          window.location.pathname + "?" + newUrlParams.toString()
        );

        setLoading(false);
      } else {
        console.error("Missing oauth_token or oauth_verifier");
      }
    } catch (error) {
      console.error("Error getting Twitter user data:", error);
    }
  };

  useEffect(() => {
    if (userAddress != null) {
      handleTwitterCallback();
      setLoading(true);
      const param = {
        wallet: userAddress,
      };

      fetch(`${process.env.REACT_APP_END_POINT}/api/v1/sign-in`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(param),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.code === 500) {
            alert(data.message);
            setUserAddress(null);
          } else {
            if (data.status === false) {
              setIsModalOpen(true);
            }
          }
          if (data.data.twitter_authen || data.data.twitter_authen !== null) {
            setTwitterName(data.data.twitter_authen);
          }
          setBaeData(data.data);
          setLoading(false);
        })
        .catch((error) => {
          alert("ERR! " + error);
          setUserAddress(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddress]);

  useEffect(() => {
    const init = async () => {
      await connectWallet();
    };
    if (!active) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account) {
      setUserAddress(account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const connectWallet = async () => {
    await activate(
      injected,
      async (error) => {
        const network = config.unsupportedChainSetup[config.chainId];
        const hasSetup = await switchChain(
          network ?? {
            chainId: `0x${parseInt(config.chainId.toString()).toString(16)}`,
          }
        );
        if (hasSetup) {
          await activate(injected, async () => {
            console.log(`${error.message}`);
          });
        } else {
          alert(`Unable to connect to required network ${config.chainId}`);
        }
      },
      false
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const Logout = () => {
    deactivate();
    setUserAddress(null);
    setBaeData(null);
  };

  const tabTitles = [
    "Profile",
    "Daily&History",
    "Mission",
    "Leader Board",
    "Enter Code",
  ];

  const tabContents = [Profile, Daily, Mision, LeaderBoard, EnterCode];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      {loading && <Loading />}
      {!userAddress && (
        <SignIn
          userAddress={userAddress}
          isWalletConnected={active}
          connectWallet={() => connectWallet()}
          disconnectWallet={() => deactivate()}
        />
      )}

      <Menu />
      <div className="bae-container">
        <div className="bae-content">
          <div className="header-tab">
            {tabTitles.map((title, index) => (
              <div
                key={index}
                onClick={() => handleTabClick(index)}
                style={{
                  cursor: "pointer",
                }}
                className={index === activeTab ? "active" : ""}
              >
                {title}
              </div>
            ))}
            <button className="testbutton  mt-3" onClick={Logout}>
              Log-out
            </button>
          </div>

          <div className="content-tab">
            <div className="notification">
              You have
              <span className="text-[#a78bfa]">
                {" "}
                {baeData && baeData.lolly_total} $Lolli🍭
              </span>
              <br />
              Complete the quest to find lolli
            </div>
            {tabContents.map((Content, index) => (
              <div
                key={index}
                style={{ display: index === activeTab ? "block" : "none" }}
                className="w-full h-full"
              >
                <Content
                  baeData={baeData}
                  userAddress={userAddress}
                  twitter={twitterName}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      {isModalOpen && (
        <>
          <div className="modal-background">
            <div className="modal-content">
              <h2>Sign-in successfully</h2>
              {baeData && (
                <>
                  <p className="font-[Rubik]">
                    {baeData.wallet && formatAddress(baeData.wallet, 4)}
                  </p>
                  <p>You got your baby, do the quest to get the lolli</p>
                  {baeData && <img src={baeData.src_image} alt="baby"></img>}
                </>
              )}
              <button className="testbutton" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
          <Confetti />
        </>
      )}
    </>
  );
};

export default MyBae;
