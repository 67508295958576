import React from "react";
import Footer from "../components/Footer/Footer";
import Begin from "../components/Begin/Begin";
import Register from "../components/Register/Register";
import Overview from "../components/Overview/Overview";
import AboutUs from "../components/AboutUs/AboutUs";
import Collections from "../components/Collections/Collections";
import FAQ from "../components/FAQ/FAQ";

function Home() {
  return (
    <>
      <div className="labs">
        <Begin></Begin>
        <Register></Register>
        <Overview></Overview>
        <AboutUs></AboutUs>
        <Collections></Collections>
        <FAQ></FAQ>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Home;
