import React from "react";
import Menu from "../components/Menu/Menu";
import "../styles/white-paper.css";
import Footer from "../components/Footer/Footer";

const WhitePaper = () => {
  return (
    <div>
      <Menu></Menu>
      <div className="white-paper">
        <div className="content-WP">
          <div className="title-paper">
            <label>WHITEPAPER OF NUDLE DUDLE</label>
            <i>Updated 21st December, 2023</i>
          </div>
          <div className="hyper-link underline">
            <div>
              <a href="/white-paper/#Introduction">Introduction</a>
            </div>
            <div>
              <a href="/white-paper/#Collection">Collection</a>
            </div>
            <div>
              <a href="/white-paper/#UtilityAndBenefits">Utility & Benefits</a>
            </div>
            <div>
              <a href="/white-paper/#Roadmap">Roadmap</a>
            </div>
          </div>
          <div className="title-wp">
            <a href="/white-paper/#Introduction">Introduction</a>
          </div>
          <div id="Introduction">
            <p>
              Thank you for being here. Take a brief 5-minute moment to truly
              understand the Nudle Dudle project, which promises to bring many
              interesting and surprising elements. Nudle Dudle is envisioned as
              a 3-in-1 project, allowing ownership of up to three different NFT
              collections. Each NFT collection represents a developmental phase
              of the project, and you will be pleasantly surprised to see your
              NFTs evolve and grow each day.
            </p>
            <p>
              In recent times, the crypto market has seen a scarcity of
              high-quality NFTs with truly beautiful artwork that justifies the
              investment. Despite the downturn in the crypto market and the
              fading direction of blue-chip NFTs, the value of NFTs persists in
              art and community. Nudle Dudle is born to symbolize an essential
              aspect: the development and true value of NFTs over time. The
              project prioritizes the quality of NFT images, and the Nudle Dudle
              art team has worked tirelessly for over a year to deliver the best
              product to the community.
            </p>
          </div>
          <div className="title-wp">
            <a href="/white-paper/#Collection">Collection</a>
          </div>
          <div id="Collection">
            <p>
              Nudle Dudle, the first NFT project with 1500+ traits, introduces
              over 80 unique characters conceptualized and drawn over a year. It
              aims to set a milestone for NFT development in 2024, justifying
              the extensive time invested by the development team to create the
              top NFTs.
            </p>
            <p>
              Drawing inspiration from the simplicity of human nature in the
              vibrant complexity of the modern world, Nudle Dudle highlights the
              intriguing contrast of a bare human in a colorful world. With over
              80 different character personalities, Nudle Dudle caters to a
              diverse audience. The three unique NFT collections—Baby Dudle,
              Nudle Dudle, and Fashion Dudle—offer various features and
              applications, promising exciting and unexpected discoveries for
              the community.
            </p>
            <p>
              Baby Dudle: This collection, gifted free to early participants,
              allows users to experience parenthood as they watch their virtual
              baby grow. Baby Dudle owners can check in daily, receiving
              $Lollipop rewards, while completing tasks adds to their reward
              tally. Importantly, Baby Dudle availability is limited to early
              participants.
            </p>
            <p>
              Nudle Dudle: Representing the core Nudle Dudle project, this
              collection holds significant value. While Baby Dudle is
              complimentary, missing out on Nudle Dudle means forgoing Airdrops,
              PvP game access, character upgrades, and more. Limited to 1998
              NFTs, Nudle Dudle's scarcity underscores its value.
            </p>
            <p>
              Fashion Dudle: An additional collection gifted to Nudle Dudle
              owners, Fashion Dudle enhances NFTs with fashionable attire.
              Fashion Dudle introduces a unique feature allowing owners to
              change and upgrade their NFTs' clothing, adding a layer of
              creativity and income through diligent in-game work.
            </p>
          </div>
          <div className="title-wp">
            <a href="/white-paper/#UtilityAndBenefits">Utility & Benefits</a>
          </div>
          <div id="UtilityAndBenefits">
            <label>Nudle Dudle: A 3-in-1 Project</label>
            <p>
              Nudle Dudle integrates three NFT collections representing
              different stages of development into one. Joining the Nudle Dudle
              community offers a plethora of benefits and unique features from
              each collection.
            </p>
            <label>NFT Excellence</label>
            <p>
              With over 1500+ unique traits, Nudle Dudle stands as the NFT
              collection with the most traits, promising the most enjoyable
              experience.
            </p>
            <label>Baby Bring Up</label>
            <p>
              Unlike pet nurturing, Baby Dudle provides an experience of
              nurturing and observing your baby's growth, contributing to family
              tasks and offering attractive rewards during care.
            </p>
            <label>Clothes Changing</label>
            <p>
              Each NFT can upgrade and change clothes based on the project's
              shared wardrobe or personally collected items through rewards and
              the store.
            </p>
            <label>$Lollipop Farming & Airdrop</label>
            <p>
              $Lollipop serves as the primary currency throughout the collection
              and plays a crucial role in the Nudle Dudle ecosystem. Most
              $Lollipop can be earned through airdrops for Nudle Dudle NFT
              holders and completing tasks in the system.
            </p>
            <label>Dudle Box</label>
            <p>
              A special reward for Nudle Dudle NFT owners, Dudle Boxes contain
              highly attractive prizes, including rare NFTs, $Lollipop, items,
              and other surprises.
            </p>
            <label>Fashion Dudle Airdrop</label>
            <p>
              Designed as an airdrop for Nudle Dudle holders, Fashion Dudle
              features a diverse and impressive collection of fashionable
              clothes. Holders can become true fashionistas and customize their
              NFTs.
            </p>
            <label>Wedding Ceremony</label>
            <p>
              A distinctive feature of Nudle Dudle is the marriage option, where
              NFTs can marry and unlock attractive rewards for their small
              family.
            </p>
            <label>PvP Game</label>
            <p>
              As part of continuous NFT development, PvP gaming is a crucial
              feature, allowing users to compete using their NFTs for valuable
              rewards.
            </p>
            <label>Item Upgrade System</label>
            <p>
              Traits and equipment can be upgraded through a store or each
              user's personal store, enabling easy enhancement of NFTs with
              various items and special equipment obtained through rewards and
              the store.
            </p>
            <label>Shop & Black Market</label>
            <p>
              The shop enables users to buy and trade items, equipment, and
              clothes for their NFT collection. The Black Market, a more
              secretive place, allows holders to acquire rare items and clothes
              that cannot be found elsewhere.
            </p>
          </div>
          <div className="title-wp">
            <a href="/white-paper/#Roadmap">Roadmap</a>
          </div>
          <div id="Roadmap">
            <label>Phase 1: Preparation</label>
            <p>
              ☸ 15/10/2022: Project idea conceptualization
              <br />☸ 5/11/2022: Completion of demos and resource exploration
              <br />☸ 10/12/2022: Formation of the art team, initiation of work
              <br />☸ 3/3/2023: Formation of the dev team under Drake's
              leadership
              <br />☸ 15/9/2023: Completion of beta project features, entering
              the testing phase
              <br />☸ 22/10/2023: Bug fixing and optimization of smart contracts
              <br />☸ 8/11/2023: Completion of over 1500+ traits and application
              features on the blockchain
            </p>
            <label>Phase 2: Official Project Launch</label>
            <p>
              ☸ 5/1/2024: Free distribution of Nudle Dudle for early
              participants, unlocking features for $Lollipop farming and rewards
              <br />☸ Early February 2024: Launch of Wallet Checker for
              Whitelist members
              <br />☸ February 2024: Official sale of Nudle Dudle with a limited
              supply of 1998 NFTs
              <br />☸ End of February 2024: Organization of contests,
              competitions, and regular minigames to engage the community
            </p>
            <label>Phase 3: Airdrop and Rewards</label>
            <p>
              ☸ Early March 2024: Airdrop of Fashion Dudle to Nudle Dudle
              holders, rare NFTs for top holders and active participants
              <br />
              ☸ Airdrop of Dudle Box to Nudle Dudle holders, containing highly
              attractive rewards and 1/1 legendary items
              <br />
              ☸ Airdrop of $Lollipop corresponding to the number of NFTs owned
              for Nudle Dudle holders
              <br />☸ Distribution of rewards through activities for active and
              early holders
            </p>
            <label>Phase 4: Expand Features and Ecosystem</label>
            <p>
              ☸ End of March 2024: Opening of Itemshop and Black Market with
              regular updates
              <br />☸ Expansion and enhancement of Nudle Dudle NFT clothing and
              equipment customization features
              <br />☸ Early April 2024: Introduction of on-chain staking,
              allowing holders to earn passive $Lollipop
            </p>
            <label>Phase 5: Develop Special Features</label>
            <p>
              ☸ Early June 2024: PvP system development with online tournaments
              for holders
              <br />☸ End of June 2024: Unlocking the Wedding Ceremony feature,
              allowing holders to search for partners and activate marriage for
              attractive rewards
              <br />☸ Early July 2024: Introduction of Dudle Draw, allowing
              holders to creatively design off-chain NFTs with rewards and
              $Lollipop
              <br />☸ End of July 2024: Dudle Showroom, a community showcase for
              unique items and rare NFTs, honoring special artwork from the
              Nudle Dudle community
            </p>
            <label>
              Phase 6: Evaluation, Feedback, and Roadmap 2.0 Development
            </label>
            <p>
              ☸ End of August: Special Dudle Box gifts for dedicated holders,
              review sessions for community feedback on the project's
              development, and gathering community opinions for Roadmap 2.0
              development.
            </p>
          </div>
          <div className="chart-img">
            <img
              src="https://amber-ready-amphibian-362.mypinata.cloud/ipfs/QmbfJRKV3ksn4s9AqsgHVhx6gtS9GeRuYMHZ17vmhfKz75?_gl=1*7gkcn4*_ga*NDc0NjgwNzAwLjE2ODIyNDAxNzI.*_ga_5RMPXG14TE*MTcwMzIzMjM2NS44MC4xLjE3MDMyMzM0NjcuNjAuMC4w"
              alt="chart"
            ></img>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default WhitePaper;
