import React from "react";
import "./begin.css";
import "../../App.css";
import Menu from "../Menu/Menu";

function Begin() {
  return (
    <>
      <div className="begin">
        <Menu></Menu>
      </div>
    </>
  );
}

export default Begin;
