import React, { useState } from "react";
import "./menu.css";

function Menu() {
  const [isChecked, setIsChecked] = useState(false);

  const updateMenu = () => {
    const menuElement = document.getElementById("menu");

    if (isChecked) {
      menuElement.style.borderBottomRightRadius = "0";
      menuElement.style.borderBottomLeftRadius = "0";
    } else {
      menuElement.style.borderRadius = "10px";
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    updateMenu();
  };

  return (
    <div className="menu-container font-[Rubik]">
      <nav id="menu">
        <input
          type="checkbox"
          id="responsive-menu"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="responsive-menu"></label>
        <ul>
          <li>
            <a className="dropdown-arrow" href="/">
              Home
            </a>
            <ul className="sub-menus">
              <li>
                <a href="/#register">Register</a>
              </li>
              <li>
                <a href="/#overview">Overview</a>
              </li>

              <li>
                <a href="/#about_us">About Us</a>
              </li>
              <li>
                <a href="/#collections">Collections</a>
              </li>
              <li>
                <a href="/#FAQ">FAQ</a>
              </li>
            </ul>
          </li>

          <li>
            <a href="/lab">Lab</a>
          </li>

          <li>
            <a href="/my-bae">My Bae</a>
          </li>
          <li>
            <a href="/white-paper">White Paper</a>
          </li>
          <li className="testbutton">
            <a href="/mint">MINT NOW</a>
          </li>
        </ul>
        <img src="/logo192.png" alt="logo"></img>
      </nav>
    </div>
  );
}

export default Menu;
